import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MemoryIcon from '@material-ui/icons/Memory';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BarChartIcon from '@material-ui/icons/BarChart';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { BNB } from 'ccy-icons';

toast.configure()

const coinTicker = require('coin-ticker');

export default class UserStats extends Component{ 
  constructor(props) {
    super(props);
    ////console.log("props", props);
    this.state = {
      userData: null,
      userName: "",
      userEmail: "",
      playerBalance:0.0000,
      token:props.token,
      tick: null,
      low: null,
      high: null,
      usdChips: null,
      bnbChips: null
    };
  }  
 
  componentDidMount() {
    try{
      this.setState({token:this.props.token});
      this.fullUserData(this.props.data, this.props.token);
      this.setup();
      setInterval(this.setup, 30000);
    }catch{
      //ignore
    }
  }

  setup = () => {
      this.bnbPriceData();
      this.userBalance();
  }

  bnbPriceData = async (amount) => {
    try{
      coinTicker("binance", "BNB_USDT")
      .then((tick) => {
        ////console.log(tick);
        this.setState({tick: parseFloat(tick.last).toFixed(2)});
        this.setState({low: parseFloat(tick.low).toFixed(2)});
        this.setState({high: parseFloat(tick.high).toFixed(2)});
      })
    }catch{
      //ignore
    }
  }

  chipsToBNB = async (amount) => {
    //1BNB:1000CHIPS
    /*
    1 CHIP TO BNB
    = 1 / 1000
    = 0.001 CHIP BNB PER CHIP
    = 0.001 * 10
    = 0.010 BNB worth of CHIPS if i have 10 CHIPS.
    */
    this.setState({bnbChips: parseFloat(0.001 * amount).toFixed(4)});
    //return 0.001 * amount;
  }

  chipsToUSD = async (amount) => {
    //1BNB:1000CHIPS
    /*
    1 CHIP TO USD
    = 500.10 / 1000
    = 0.5001 USD PER CHIP
    = 0.5001 * 10
    = $5.001 worth for chips if i have 10 CHIPS.
    */

    //this.componentDidMount();
    var pricePerChip = parseFloat(this.state.tick) / 1000;
    this.setState({usdChips: parseFloat(pricePerChip * amount).toFixed(2)});
    //return pricePerChip * amount;
  }
  
  userBalance = async () => {
    var token = this.state.token;
    var res = await fetch('https://api.bnb.poker/api/users/playerBalance?access_token=' + token, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(playerBalance => playerBalance.json())
  
    ////console.log("balance", res);
    this.chipsToBNB(res.playerBalance);
    this.chipsToUSD(res.playerBalance);
    this.setState({playerBalance: res.playerBalance});
   }

   fullUserData = async (userId, token) => {
    var res = await fetch('https://api.bnb.poker/api/users/' + userId + "?access_token=" + token, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(userData => userData.json())
  
    //console.log("fullUserData", res);
    this.setState({userName: res.username});
    this.setState({userEmail: res.email});
   }

  render(){

    var data = this.props.data;
    var token = this.props.token;
    ////console.log("this.props", this.props);
    
    ////console.log("state.userData", this.state.userData);
    ////console.log("username:", data);
    ////console.log("token:", token);
    if(!token) {
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            Please sign in...       
          </div>
        </Container>
      );
    }else{
      //
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div style={{"text-align" : "left"}}>
            <br />
            <AlternateEmailIcon style={{ color: "#fcc413" }} /> <b>Username:</b> {this.state.userName} <br />
            <MailOutlineIcon style={{ color: "#fcc413" }} /> <b>Email:</b> {this.state.userEmail} <br />
            <PeopleAltIcon style={{ color: "#fcc413" }} /> <b>User ID:</b> {data} <br /> <br />
            <MemoryIcon style={{ color: "#fcc413" }} /> <b>Balance CHIPS:</b> {this.state.playerBalance} <br />
            <BNB style={{"width" : "20px", color: "#fcc413"}} /> <b>Balance BNB:</b> {this.state.bnbChips} <br />
            <MonetizationOnIcon style={{ color: "#fcc413" }} /> <b>Balance USD:</b> {this.state.usdChips} <br />
            <BarChartIcon style={{ color: "#fcc413" }} /> <b>Ticker:</b> BNB/USDT {this.state.tick} <br />
            <br /><br />
          </div>
        </Container>
      );
    }
  }
}