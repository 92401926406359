import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';

toast.configure()

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fcc413",
    "background-color": "#2b2e35"
  },
}));

async function loginUser(credentials) {
  return fetch('https://api.bnb.poker/api/users/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }

export default function SignIn({ setToken, setData}) {
  const classes = useStyles();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [human, setHuman] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    toast.info("Attempting login...");
    if(!human){
      toast.error("Are you even a human ?");

    }else{
      const res = await loginUser({
        username,
        password,
        human:human
      });
      
      if(res.error){
        toast.error("Error: " + res.error.message);
      }else{
        ////console.log("res", res);
        setData(res.userId);
        setToken(res.id);
        toast.success(res.userId);
      }
    }
   
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {
        //<Avatar className={classes.avatar}>
        //  <LockOutlinedIcon />
        //</Avatar>
        //<Typography component="h1" variant="h5">
        //  Sign in
        //</Typography>
        }
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="User Name"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={e => setUserName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
          />
          <HCaptcha
            sitekey="616467d7-65f5-4b0e-8643-756cd5e1dd3c"
            onVerify={(token,ekey) => {
              ////console.log("token", token);
              ////console.log("ekey", ekey);
              setHuman(token);
            }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" checked/>}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              {
              //<Link href="#" variant="body2">
                //{"Don't have an account? Sign Up"}
              //</Link>
             }
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

SignIn.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired
}
