import { useState } from 'react';

export default function useUserData() {

  const getData = () => {
    const tokenString = sessionStorage.getItem('userData');
    const userData = JSON.parse(tokenString);
    //console.log("userData", userData);
    return userData
  };

  const [data, setData] = useState(getData());

  const saveData = userData => {
    //console.log("saveData", userData);
    sessionStorage.setItem('userData', JSON.stringify(userData));
    setData(userData);
  };

  return {
    setData: saveData,
    data
  }
}