import './App.css';
import Iframe from 'react-iframe'
import React, { Component } from 'react';
import { BNB } from 'ccy-icons';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
const coinTicker = require('coin-ticker');


export default class PlayBar extends Component{ 
  constructor(props) {
    super(props);
    ////console.log("props", props);
    this.state = {
      tick: null,
      low: null,
      high: null,
      symbol: "BNB_USDT",
      exchange: "binance"
    };
  }  
 
  componentDidMount() {
    this.setState({symbol: this.props.symbol});
    this.getTick();
    setInterval(this.getTick, 10000);
  }

  getTick = async (symbol = null) => {
    if(!symbol){
      symbol = this.state.symbol;
    }
    symbol = "BNB_USDT";
    /*
    var res = await fetch('https://api.bnb.poker/api/users/playerBalance?access_token=' + token, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(tick => tick.json())
  
    //console.log("tick", res);
    this.setState({tick: res.tick});
    */
    /*
    coinTicker('binance', 'pairs')
    .then((pairs) => {
      //console.log("pairs", pairs);
    })
    */
    try{
      console.log("symbol:", symbol);
      coinTicker(this.state.exchange, symbol)
      .then((tick) => {
        console.log(tick);
        this.setState({tick: parseFloat(tick.last).toFixed(2)});
        this.setState({low: parseFloat(tick.low).toFixed(2)});
        this.setState({high: parseFloat(tick.high).toFixed(2)});
      })
    }catch{
      //ignore
    }
  };

  render(){

    //var symbol = this.props.symbol;
    ////console.log("tick", this.state.tick);
    ////console.log("username:", data);
    ////console.log("token:", token);
    // style={{"margin-top":"10px", "font-size" : "large"} }
    return (
      <div>
        <div className="App">
          <a href="https://www.binance.com/en/register?ref=25211256"  rel="noreferrer" target="_blank">
            <BNB style={{"width" : "20px", color: "#fcc413"}} /></a> {this.state.tick} 👇 {this.state.low} ☝️ {this.state.high} 
          {
            //<AttachMoneyIcon style={{ color: "#fcc413" }} />
          }       
        </div>
        <div>
          <Iframe url="https://client.bnb.poker"
            width="100%"
            height="1100px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"/>
        </div>
      </div>
    );
  }
}