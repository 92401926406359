import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';

toast.configure()

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fcc413",
    "background-color": "#2b2e35"
  },
}));

//{pokerPass: "test", username: "test", email: "test@test.com", id: "6064ec9b5538b96ef8ca5455", human:"captcha-token"}
async function signUpUser(credentials) {
  return fetch('https://api.bnb.poker/api/users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }


export default function SignUp() {
  const classes = useStyles();

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [human, setHuman] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    
    if(password.length < 6){
      toast.error("Please make your password at least 6 chars long...");
      return;
    }

    if(username.includes("admin") || username.includes("bnb.poker") || username.includes("bnbpoker")){
      toast.error("you can not use that word!");
      return;
    }

    if(human){
      e.preventDefault();
      toast.info("Creating account");
      var pokerPass = password;
      const res = await signUpUser({
        username,
        password,
        pokerPass,
        email,
        human
      });
      
      console.log("res 1", res);
      if(res.error){
        console.log("Error:", res.error.message);
        toast.error("Error: " + res.error.message);
      }else{
        console.log("res", res);
        //setToken(res.id);
        if(res.id){
          //toast.success(res.id);
          toast.success("You may now login!");
        }else{
          toast.error("Error: creating account");
        }
      }
    }else{
      toast.error("Please complete captcha!");
    }

  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      {
       //<Avatar className={classes.avatar}>
       // <LockOutlinedIcon />
       //</Avatar>
        
        //<Typography component="h1" variant="h5">
        //  Sign up
        //</Typography>
        }
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="username"
                name="username"
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Username"
                autoFocus
                onChange={e => setUserName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
            <HCaptcha
              sitekey="616467d7-65f5-4b0e-8643-756cd5e1dd3c"
              onVerify={(token,ekey) => {
                ////console.log("token", token);
                ////console.log("ekey", ekey);
                setHuman(token);
              }}
            />
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" checked/>}
                label="I agree to the terms of service and consider poker a skill based game."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              
              {
              //<Link href="#" variant="body2">
              //  Already have an account? Sign in
              //</Link>
              }
            </Grid>
          </Grid>
        </form>
      </div>

    </Container>
  );
}

