import './App.css';
import SignIn from './SignIn.js';
import SignUp from './SignUp.js';
import StickyFooter from './StickyFooter.js';
import React from 'react';
import Button from '@material-ui/core/Button';
import useToken from './useToken';
import useUserData from './useUserData';
import UserStats from './UserStats';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import DepositPopUp from './DepositPopUp';
import WithdrawPopUp from './WithdrawPopUp';
import BnbTicker from './BnbTicker';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import HelpIcon from '@material-ui/icons/Help';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {Helmet} from "react-helmet";
import {Route, BrowserRouter as Router} from "react-router-dom";
import PlayBar from './PlayBar';


toast.configure()

const buttonStyle = {
  borderRadius: 35,
  backgroundColor: "#fcc413",
  color:"#000",
  padding: "18px 36px",
  fontSize: "12px",
  fontWeight: "bold",
  float:"left"
};

async function logoutUser(token) {
  toast.info("Attempting logout...");
  return await fetch("https://api.bnb.poker/api/users/logout?access_token=" + token, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST"
  })
}

async function playRedirect() {
  toast.info("Have fun playing poker ...");
  window.location.href="https://play.bnb.poker";  
}

function App() {

  const { data, setData } = useUserData();
  const { token, setToken } = useToken();

  
  const logOut = (e) => {
    e.preventDefault();
    //alert('Thanks, we hope you enjoyed your game');
    logoutUser(token).then((res) =>{
      //console.log("logoutUser res", res);
      if(res.status === 401){
        toast.warning("You are already logged out!");
      }
      if(res.status === 200 || res.status === 204 ){
        toast.success("See you next time !");
      }
      sessionStorage.clear();
      setTimeout(() => {  window.location.reload(); }, 2000);      
    });
  }

  ////console.log("token:", token);
  ////console.log("userdata:", data);

  if(window.location.href.includes("play")){
    return (
      <Router>
        <div>
          <Route path='/play' exact component={PlayBar} />
        </div>
      </Router>
    );
  }

  if(!token) {
     return (
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Bnb.Poker UI</title>
            <meta name="description" content="Player Poker with BNB coin!" />
            <meta name="keywords" content="poker, bnb, crypto, bitcoin, binance" />
            <meta NAME="robot" CONTENT="index,nofollow" />
            <link rel="canonical" href="https://ui.bnb.poker" />
          </Helmet>
          <header className="App-header">
            <div className="menu">
               <BnbTicker symbol="BNB_USDT" />
              <div className="button">
                  {
                  //<Button style={//buttonStyle} variant="contained">Stats</Button>
                  }
              </div>
            </div>
          </header>
              
          <div className="container">
            <div className="left">
              <SignUp />
            </div>
            <div className="center">
                  <a href="https://www.binance.com/en/use-bnb/" rel="noreferrer" target="_blank"><img src="bnb-logo.png" className="App-logo" alt="logo" /></a>
                  <a href="https://discord.gg/uWnhFxDVFQ" rel="noreferrer" target="_blank"><QuestionAnswerIcon style={{ color: "#fcc413" }} /></a>
                  <a href="https://discord.gg/kbR4sJjxfa" rel="noreferrer" target="_blank"><HelpIcon style={{ color: "#fcc413" }} /></a>
                  {
                    //<a href="https://www.binance.com/en/use-bnb/"  rel="noreferrer" target="_blank"><BNB style={{"width" : "20px", color: "#fcc413"}} /></a>
                  }
                  <MailOutlineIcon style={{ color: "#fcc413" }} /> 
            </div>
            <div className="right">
              <SignIn setToken={setToken} setData={setData} />  
            </div>
        </div>
  
          <StickyFooter />  
      </div>
    );
  }

  return (
      <div className="App">
        <header className="App-header">
          <div className="menu">
            <div className="button">
                <Button style={buttonStyle} variant="contained" onClick={playRedirect}>
                    Play 🔥
                </Button>
          
                <DepositPopUp token={token} modal></DepositPopUp>      

                <WithdrawPopUp token={token} trigger={
                  <Button style={buttonStyle} variant="contained">
                    Withdraw 💰
                  </Button>
                } modal></WithdrawPopUp>  

                <Button style={buttonStyle} variant="contained" onClick={logOut}>
                    Logout ❌
                </Button>
            </div>
            
          </div>
        </header>
        {
          //BnbTicker symbol="BNB_USDT" />
        }
        <div className="container">
          <div className="left">
            <UserStats token={token} data={data}/>
          </div>
          <div className="center">
                {
                  //<img src="bnb-logo.png" className="App-logo" alt="logo" />
                }
          </div>
          <div className="right">
             
          </div>
      </div>
 
        <StickyFooter />  
    </div>
  );
}

export default App;
