import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { BNB } from 'ccy-icons';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit" href="https://bnb.poker">
        Bnb.Poker
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width:"100%",
    "vertical-align": "top",
    display: 'flex',
    flexDirection: 'column',
    minHeight: '10vh',
    maxHeight: '50vh',

  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    "vertical-align": "top",
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          {
            //<Typography variant="body1">♠️ </Typography>
            //<Typography variant="body1">♣️ </Typography>
          }
          <Typography variant="body1"><a href="https://www.responsiblegambling.org/"  rel="noreferrer" target="_blank"><img style={{"width" : "40px", color: "#fcc413"}} src="responsible_gaming_18_pluss.png" alt="18+ responsible gaming"></img></a> <a href="https://discord.gg/uWnhFxDVFQ"  rel="noreferrer" target="_blank"><img style={{"width" : "40px", color: "#fcc413"}} src="discord-logo.png" alt="Connect with us on Discord chat!"></img></a></Typography>
          {
            //<Typography variant="body1">Poker is a game of skill, yet we still support <a href="https://en.wikipedia.org/wiki/Responsible_gambling"  rel="noreferrer" target="_blank">Responsible Gaming </a>Initiatives</Typography>
          }
          <Typography variant="body1">We ❤️ <a href="https://www.binance.com/en/use-bnb/"  rel="noreferrer" target="_blank"><BNB style={{"width" : "17px", color: "#fcc413"}} /></a> 🃏</Typography>
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}