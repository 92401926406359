import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { Button, FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import CachedIcon from '@material-ui/icons/Cached';

toast.configure()

const buttonStyle = {
  borderRadius: 35,
  backgroundColor: "#fcc413",
  color:"#000",
  padding: "18px 36px",
  fontSize: "12px",
  fontWeight: "bold",
  float:"left"
};

export default class DepositPopUp extends Component{

  constructor(props) {
    
    super(props);
    ////console.log("props", props);

    this.state = {
      token:props.token,
      error: null,
      loading: false,
      depositAmount: 0.00,
      amount: null,
      txn_id: null,
      address: null,
      qrcode_url: null,
      status_url: null,
      checkout_url: null,
      confirms_needed: null,
      timeout: null,
      time_created: null,
      time_expires: null,
      status: 0,
      status_text: null,
      receivedf: 0,
      recv_confirms: 0,
      human:null
    };
  }  

  componentDidMount() {
    //console.log("token token", this.props.token);
    this.setState({token:this.props.token});
    //console.log("this.state.token", this.state.token);
    this.checkDeposit();
    //this.checkDeposit(token, this.state.txn_id);
    setInterval(this.checkDeposit, 30000);
  }

  createDeposit = async (token, amount) => {
    this.setState({loading:true});

    amount = parseFloat(amount);
    //console.log("amount", amount);
    //console.log("token", token)
    
    if(amount < 0.001){
      toast.error("Error: Min deposit is 0.001");
      this.setState({error: "Min deposit is 0.001"});
      this.setState({loading:false});
      return;
    }

    if(this.state.human == null){
      toast.error("Error: Please confirm you are human");
      this.setState({error: "Please confirm you are human"});
      this.setState({loading:false});
      return;
    }

    var res = await fetch('https://api.bnb.poker/api/users/createDeposit?amount=' + amount.toString() + '&human=' +this.state.human+'&access_token=' + token, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(deposit => deposit.json())
    
    res = res.createDeposit;
    this.setState({loading:false});
    
    if(res.name === "Error"){
      toast.error("Error: " + res.extra.error);
      this.setState({error: res.extra.error});
    }else if(res.toString().includes("Error")){
      toast.error("Error: " + res.toString());
      this.setState({error: res.toString()});
    }else{
      //console.log("deposit", res);
      this.setState({amount: res.amount});
      this.setState({txn_id: res.txn_id});
      this.setState({address: res.address});
      this.setState({qrcode_url: res.qrcode_url});
      this.setState({status_url: res.status_url});
      this.setState({checkout_url: res.checkout_url});
      this.setState({confirms_needed: res.confirms_needed});
      this.setState({timeout: res.timeout});

      toast.success("Deposit " + res.amount + " to " + res.address);

      this.checkDeposit(token, res.txn_id);

    }
  }

  checkDeposit = async () => {
    var token = this.state.token;
    var txid = this.state.txn_id;

    if(txid != null){
      this.setState({loading:true});

      //console.log("checkDeposit: txid -", txid)
      
      var res = await fetch('https://api.bnb.poker/api/users/depositStatus?txid=' + txid + '&access_token=' + token, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(status => status.json())
      
      res = res.depositStatus;

      this.setState({loading:false});

      if(res.name === "Error"){
        toast.error("Error: " + res.extra.error);
        this.setState({error: res.extra.error});
      }else if(res.toString().includes("Error")){
        toast.error("Error: " + res.toString());
        this.setState({error: res.toString()});
      }else{
        //console.log("depositStatus", res);

        this.setState({time_created: res.time_created});
        this.setState({time_expires: res.time_expires});
        this.setState({status: res.status});
        this.setState({status_text: res.status_text});
        this.setState({receivedf: res.receivedf});
        this.setState({recv_confirms: res.recv_confirms});
        //this.setState({confirms_needed: res.confirms_needed});
        //this.setState({timeout: res.timeout});

        //if( res.status.toString() == "3"){
          //toast.success("Congratulations payment is complete !");
        //}

        toast.info("Status: Received " + res.receivedf + " , Time Created: " + res.time_created);
      }
    }   
  }  
  
  changeValueState = async (e) => {
    ////console.log(e.target.name);
    ////console.log(e.target.name);
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  unixToTime = (timeStamp) => {
    ////console.log("timeStamp", timeStamp);
    var date = new Date(timeStamp * 1000);
    ////console.log("date", date);
    //return date.toString();
    var hours = date.getHours();
    var day = date.getDay();
    var month = date.getMonth();
    //var year = date.getFullYear();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();
    var time = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) + " " + day + "/" + month ;
    ////console.log("time", time);
    return time;
  }

  timeLeft = (date1, date2) => {
    var difference = date1 - date2;

    var daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    var hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60

    var minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60

    var secondsDifference = Math.floor(difference/1000);

    ////console.log('difference = ' +
    //  daysDifference + ' day/s ' +
    //  hoursDifference + ' hour/s ' +
    //  minutesDifference + ' minute/s ' +
    //  secondsDifference + ' second/s ');

    return hoursDifference + ' hour/s ' + minutesDifference + ' minute/s ' + secondsDifference + ' second/s ';
  }

  render(){
    //console.log("this.props", this.props);
    //console.log("Loading:", this.state.loading);
    var token = this.props.token;
    
    if(this.state.status.toString() === "100"){
      toast.success("Congratulations payment is complete !");
      return(
        <Popup
          trigger={ <Button style={buttonStyle} variant="contained">Deposit ✔️</Button> }
          modal
          nested
        >
        
        {close => (
          <div className="modal">
            <button className="close" onClick={() => {
                this.setState({status:0});
                close();
              }}>
            ❌
            </button>
            <br />
            <div className="header"> &thinsp; </div>
            <div className="center">
            <button
              className="button"
              onClick={() => {
                this.setState({status:0});
                close();
              }}
              >
                Close
            </button>
            {<div style={{"color" : "green"}}>{this.state.status_text}</div>}
            </div>       
          </div>
        )}
    </Popup>
      );
    }

    if(this.state.loading){
      //toast.info("Working...");
      return(
        <Popup
        trigger={ <Button style={buttonStyle} variant="contained">Deposit ✔️</Button> }
        modal
        nested
      >
        
        {close => (
          <div className="modal">
            <button className="close" onClick={close}>
            ❌
            </button>
            <br />
            <div className="header"> &thinsp; </div>
            <div className="center">
              <img src="bnb-logo.png" className="App-logo" alt="logo" />
              <br />
              Your deposit is being created
            </div>       
          </div>
        )}
    </Popup>
      );
    }

    if(this.state.address != null){    
     return(
      <Popup
        trigger={ <Button style={buttonStyle} variant="contained">Deposit ✔️</Button> }
        modal
        nested
      >
        
        {close => (
          <div className="modal">
            <button className="close" onClick={close}>
            ❌
            </button>
            <br />
            <div className="header"> &thinsp;  </div>
            <div>
              Please send <b>{this.state.amount}</b> BNB to <b>{this.state.address}</b><br />
              (Confirmations Needed: {this.state.confirms_needed}) <br />
              <b>Timeout:</b> {this.state.timeout} <br />
              <div className="center">
                <img src={this.state.qrcode_url} className="qr" alt={"Deposit " + this.state.amount + " to " + this.state.address} />

                <button
                    className="button"
                    onClick={() => {
                      this.setState({loading:true});
                      this.checkDeposit(token, this.state.txn_id);
                    }}
                  >
                  <CachedIcon style={{ color: "#fcc413" }} />
                </button>

                <button
                    className="button"
                    onClick={() => {
                      //window.location.href=this.state.status_url;
                      window.open(this.state.status_url, '_blank');
                    }}
                  >
                    Refrence
                </button> 
              </div>  
              
              <div>
                  <br />
                  <b>Status:</b> {this.state.status_text} <br />
                  <b>Status Code:</b> {this.state.status} <br />
                  <b>Received:</b> {this.state.receivedf} BNB <br />
                  <b>Time Created:</b> {this.unixToTime(this.state.time_created)} <br />
                  <b>Time Expires</b> {this.unixToTime(this.state.time_expires)} <br />
                  <b>Time Left:</b> {this.timeLeft(this.state.time_created, this.state.time_expires)} <br />

                </div>
            </div>       
          </div>
        )}
    </Popup>
     );     
    }

    return(
      <Popup
        trigger={ <Button style={buttonStyle} variant="contained">Deposit ✔️</Button> }
        modal
        nested
      >
      
      {close => (
        <div className="modal">
          <button className="close" onClick={close}>
          ❌
          </button>
          <br />
          <div className="header"> &thinsp;  </div>
          <div>
            {' '}
            If you use the official binance wallet browser app you can use cross chain transfer between BSC and BNB! <br />
            We currently only use BNB chain and not yet BSC, please insure your deposits are made on BNB chain (or cross chain transfer) and not BSC.
            <br /><br />
            Deposits are automated and confirmed after 10 blockchain confirmations (This can take up to 10min).
            <br /><br />
            By depositing and using our service you agree to the terms of use found below.
            <br /><br />
          </div>
          <div className="actions">
            <FormControl>
              <InputLabel htmlFor="my-input">Amount to deposit (BNB)</InputLabel>
              <Input id="depositAmount" name = "depositAmount" value={this.state.depositAmount} onChange={this.changeValueState} aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Our rooms operate with fractional chips 1 CHIP can go a LONG WAY. 1BNB:1000CHIPS Min - deposit 0.001 BNB.
              </FormHelperText>
              <HCaptcha
                sitekey="616467d7-65f5-4b0e-8643-756cd5e1dd3c"
                onVerify={(token,ekey) => {
                  ////console.log("token", token);
                  ////console.log("ekey", ekey);
                  this.setState({human:token});
                }}
              />
              <button
              className="button"
              onClick={() => {
                //console.log('deposit');
                this.setState({loading:true});
                this.createDeposit(token, this.state.depositAmount);
              }}
            >
              Create Deposit
            </button>
            <Popup
              trigger={<button className="button"> Terms Of Service </button>}
              position="top center"
              modal
              nested
            >
              <div  style={{"border-style" : "solid"}}>
                - We currently only use BNB chain and not yet BSC, please insure your deposits and withdraws are made on BNB chain and not BSC. <br />
                - Withdraws are processed three times per day, Monday to Friday and once per day on the weekend. <br />
                - Deposits are automated and confirmed after 5 blockchain confirmations (almost instant on BNB network). <br />
                - Withdrawals are automated but require a manual release for players that have been with us for under 1 month.  <br />
                - We will never ask you for KYC, the manual verification is to stop system fraud.  <br />
                - We have a ZERO collusion and ZERO bot policy, we pride our self on having only real players and strive to make a fair playing environment for all patrons. <br />
                - If you are found colluding by our anti cheat engine you will be investigated.    <br />
              </div>
            </Popup>
            {<div style={{"color" : "red"}}>{this.state.error}</div>}
            </FormControl>
          </div>      
        </div>
      )}
    </Popup>
    )}
}

/*
amount: {this.state.amount} <br />
txn_id: {this.state.txn_id} <br />
address: {this.state.address} <br />
qrcode_url: {this.state.qrcode_url} <br />
status_url: {this.state.status_url} <br />
checkout_url: {this.state.checkout_url} <br />
confirms_needed: {this.state.confirms_needed} <br />
timeout: {this.state.timeout} <br />
*/