import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { Button, FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import MemoryIcon from '@material-ui/icons/Memory';
import HCaptcha from '@hcaptcha/react-hcaptcha';

toast.configure()

const buttonStyle = {
  borderRadius: 35,
  backgroundColor: "#fcc413",
  color:"#000",
  padding: "18px 36px",
  fontSize: "12px",
  fontWeight: "bold",
  float:"left",
  human:null
};

export default class WithdrawPopUp extends Component{

  constructor(props) {
    
    super(props);
    console.log("props", props);

    this.state = {
      error: null,
      loading: false,
      playerBalance: 0.00,
      withdrawAmount: 0.00,
      withdrawAddress: "bnb1qklk690f8v9efdxmajemds4kjs7xgh8ak37k6z",
      withdrawed:false
    };
  }  

  componentDidMount() {
    this.userBalance(this.props.token);
  }

  createWithdraw = async (token, amount, address) => {
    this.setState({loading:true});

    amount = parseFloat(amount);
    console.log("amount", amount);
    console.log("token", token)
    
    if(this.state.human == null){
      toast.error("Error: Please confirm you are human");
      this.setState({error: "Please confirm you are human"});
      this.setState({loading:false});
      return;
    }
    
    if(amount < 1){
      toast.error("Error: Min withdraw is 1");
      this.setState({error: "Min withdraw is 1"});
      this.setState({loading:false});
      return;

    }else if(amount > this.state.playerBalance){
      toast.error("Error: " + amount + " is over " + this.state.playerBalance);
      this.setState({error: amount + " is over " + this.state.playerBalance});
      this.setState({loading:false});
      return;

    }else{
      var res = await fetch('https://api.bnb.poker/api/users/createWithdraw?amount=' + amount.toString() + '&address=' + address + '&human=' +this.state.human + '&access_token=' + token, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(withdraw => withdraw.json())

      res = res.createWithdraw;
      //console.log("res withdraw", res);
      this.setState({loading:false});
  
      if(res.error){
        console.log("res.error", res.error);
        toast.error("Error: " + res.error);
        this.setState({error: res.error});
        this.setState({loading:false});
        return;

      }else if(res.toString().includes("Error")){
        toast.error("Error: " + res.toString());
        this.setState({error: res.toString()});
        this.setState({loading:false});
        return;

      }else{
        console.log("withdraw", res);
        //this.setState({amount: res.amount});
        this.setState({withdrawed:true});

        toast.success("Withdraw pending " + this.state.withdrawAmount.toString() + "chips to " + this.state.withdrawAddress);
        this.setState({loading:false});
        return;  

      }
    }
  }

  userBalance = async (token) => {
    var res = await fetch('https://api.bnb.poker/api/users/playerBalance?access_token=' + token, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(playerBalance => playerBalance.json())
  
    console.log("balance from withdraw popus", res);
    
    this.setState({withdrawAmount: res.playerBalance});
    this.setState({playerBalance: res.playerBalance});
  }
  
  changeValueState = async (e) => {
    //console.log(e.target.name);
    //console.log(e.target.name);
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render(){
    console.log("this.props", this.props);
    console.log("Loading:", this.state.loading);
    var token = this.props.token;

    if(this.state.withdrawed){
      return(
        <Popup
        trigger={ <Button style={buttonStyle} variant="contained">Withdraw 💰</Button> }
        modal
        nested
      >
        
        {close => (
          <div className="modal">
            <button className="close" onClick={close}>
            ❌
            </button>
            <br />
            <div className="header"> &thinsp; </div>
            <div className="center">
              {
                //<img src="bnb-logo.png" className="App-logo" alt="logo" />
              }
              Withdraw pending {this.state.withdrawAmount.toString()} chips to {this.state.withdrawAddress}
            </div>       
          </div>
        )}
    </Popup>
      );
    }

    if(this.state.loading){
      //toast.info("Working...");
      return(
        <Popup
        trigger={ <Button style={buttonStyle} variant="contained">Withdraw 💰</Button> }
        modal
        nested
      >
        
        {close => (
          <div className="modal">
            <button className="close" onClick={close}>
            ❌
            </button>
            <br />
            <div className="header"> &thinsp; </div>
            <div className="center">
              <img src="bnb-logo.png" className="App-logo" alt="logo" />
            </div>       
          </div>
        )}
    </Popup>
      );
    }

    return(
    <Popup
    trigger={ <Button style={buttonStyle} variant="contained">Withdraw 💰</Button> }
    modal
    nested
  >
    
    {close => (
      <div className="modal">
        <button className="close" onClick={close}>
        ❌
        </button>
        <br />
        <div className="header"> &thinsp;  </div>
        <div>
          {' '}
          Insure you submit a BNB Chain address (starts with bnb) and not a BSC chain address, for the time being we are only processing withdraws to BNB Chain. <br />
          <br />
          - Withdraws are made 3 times a day Monday - Friday and Once a day on the weekends
          <br />
          - Be sure you reach out on <a href="https://discord.gg/uWnhFxDVFQ"  rel="noreferrer" target="_blank">Discord</a> have you any issues
          <br /><br />
        </div>

        <MemoryIcon style={{ color: "#fcc413" }} /> <b>Chip Balance:</b> {this.state.playerBalance} 
        <br /><br />
        <div className="actions">
         
          <FormControl>
            <InputLabel htmlFor="my-input">Amount to withdraw (CHIPS)</InputLabel>
            <Input id="withdrawAmount" name = "withdrawAmount" value={this.state.withdrawAmount} onChange={this.changeValueState} aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">Please remember withdraws are not instant they happen 3 times a day.</FormHelperText>
            < br />
            <Input id="withdrawAddress" name = "withdrawAddress" value={this.state.withdrawAddress} onChange={this.changeValueState} aria-describedby="my-helper-text-two" />
            <FormHelperText id="my-helper-text-two">Be sure to use a BNB address, not BSC (it will start with BNB).</FormHelperText>
            <HCaptcha
                sitekey="616467d7-65f5-4b0e-8643-756cd5e1dd3c"
                onVerify={(token,ekey) => {
                  //console.log("token", token);
                  //console.log("ekey", ekey);
                  this.setState({human:token});
                }}
              />
            <button
              className="button"
              onClick={() => {
                console.log('withdraw');
                this.setState({loading:true});
                this.createWithdraw(token, this.state.withdrawAmount, this.state.withdrawAddress);
              }}
            >
            Withdraw
          </button>
          <Popup
            trigger={<button className="button"> Terms Of Service </button>}
            position="top center"
            modal
            nested
          >
            <div  style={{"border-style" : "solid"}}>
              - We currently only use BNB chain and not yet BSC, please insure your deposits and withdraws are made on BNB chain and not BSC. <br />
              - Withdraws are processed three times per day, Monday to Friday and once per day on the weekend. <br />
              - Deposits are automated and confirmed after 5 blockchain confirmations (almost instant on BNB network). <br />
              - Withdrawals are automated but require a manual release for players that have been with us for under 1 month.  <br />
              - We will never ask you for KYC, the manual verification is to stop system fraud.  <br />
              - We have a ZERO collusion and ZERO bot policy, we pride our self on having only real players and strive to make a fair playing environment for all patrons. <br />
              - If you are found colluding by our anti cheat engine you will be investigated.    <br />
            </div>
          </Popup>
          {<div style={{"color" : "red"}}>{this.state.error}</div>}
          </FormControl>

        </div>
      
      </div>
    )}
  </Popup>
 )}
}